import React from "react"
import SinglePageHeader from "../../components/SinglePageLayout"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function Csr() {
  const { t } = useTranslation()

  return (
    <SinglePageHeader title="ICH Bursary" bg="/assets/img/csr-banner.jpg">
      <article className="ich-post ich-container">
        <h3 className="post-title">{t("bursary-ite-title")}</h3>
        <hr />
        <p>{t("bursary-ite-p1")}</p>
        <p>{t("bursary-ite-p2")}</p>
      </article>
    </SinglePageHeader>
  )
}
